const greeting = () => {
    const greeting = 'How are you feeling today?'

return(
    <>
    {greeting}
    </>
)

}

export default greeting